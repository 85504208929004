<template>
  <div id="login_wrapper">
    <div class="logout_area_wrap">
      <div class="logout_area text-center">
        <div class="logout_img"></div>
        <div class="ft18 mt-40">
          관리도구에서 안전하게
          <span class="text-danger">로그아웃</span>하였습니다.
        </div>
        <router-link :to="{ name: 'Login' }">
          <button type="button" class="btn btn-lg btn-secondary mt-40">
            확인
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>
